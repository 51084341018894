import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f974ff9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_ViewTemplate = _resolveComponent("ViewTemplate")!

  return (_openBlock(), _createBlock(_component_ViewTemplate, { title: "Test" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Alerts, {
          msg: "Ahoj",
          priority: 1
        })
      ])
    ]),
    _: 1
  }))
}

import { Options, Vue } from "vue-class-component";
import Alerts from "@/components/Alerts.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";

@Options({
  components: {
    Alerts,
    ViewTemplate,
  },
})
export default class Test extends Vue {}
